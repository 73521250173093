export default ({ res, route }) => {
  if (process.server) {
    const isRequireAuth = route.matched.some(
      matchedRoute => matchedRoute.meta.isRequireAuth
    );

    if (isRequireAuth) {
      res.setHeader('Pragma', 'no-cache');
      res.setHeader(
        'Cache-Control',
        'no-store, no-cache, must-revalidate, proxy-revalidate'
      );
      res.setHeader('Expires', 0);
    }
  }
};
