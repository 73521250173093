/**
 * TapPay的形式
 * @enum {number}
 */
const TAPPAY_TYPE = {
  FIELDS: 1,
  BUTTON: 2,
};

/**
 * TapPay欄位的狀態碼
 * @enum {number}
 */
const TAPPAY_FIELDS_STATUS_CODE = {
  SUCCESS: 0,
  EMPTY: 1,
  ERROR: 2,
  TYPING: 3,
};

export { TAPPAY_TYPE, TAPPAY_FIELDS_STATUS_CODE };
