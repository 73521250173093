/**
 * 需要追蹤的地方：
 * (A) 每個頁面的瀏覽
 * (B) 搜尋
 *
 * 跟成效追蹤跟再行銷有關的地方：
 * (1) 瀏覽產品 -> (2) 放進購物車 -> (3)開始結帳 -> (4) 完成付款
 *
 * ==================================================================================================
 *
 * Facebook Pixel
 * {@link https://developers.facebook.com/docs/facebook-pixel/reference }
 * {@link https://developers.facebook.com/docs/facebook-pixel/implementation/dynamic-ads }
 *
 * (A) PageView
 *
 * (B) Search (content_category, content_ids, contents, currency, search_string, value)
 *
 * (1) ViewContent (content_ids*, content_name, content_type*, contents*, currency, value)
 * (2) AddToCart (content_ids*, content_name, content_type*, contents*, currency, value)
 * (3) InitiateCheckout (content_category, content_ids, contents, currency, num_items, value)
 * (4) Purchase (content_ids*, content_name, content_type*, contents*, currency!, num_items, value!)
 *
 * !: Required
 * *: Required for Dynamic Ads
 *
 * ==================================================================================================
 *
 * Google Analytics
 * {@link https://developers.google.com/analytics/devguides/collection/gtagjs/ }
 * {@link https://developers.google.com/gtagjs/reference/event }
 *
 * (A) page_view
 *
 * (B) search (search_term)
 *
 * (1) view_item (items)
 * (2) add_to_cart/remove_from_cart (value, currency, items)
 * (3) begin_checkout (value, currency, items, coupon)
 * (3) checkout_progress (value, currency, items, coupon, checkout_step, checkout_option)
 * (4) purchase (transaction_id, value, currency, tax, shipping, items, coupon)
 *
 * ==================================================================================================
 */

import fbq from 'fbq';
import gtag from 'gtag';
import _lt from '_lt';

export default ({ app }, inject) => {
  inject('fbq', fbq);
  inject('gtag', gtag);
  inject('_lt', _lt);

  const { router } = app;

  /** {@link https://router.vuejs.org/zh/guide/advanced/navigation-guards.html } */
  // router.afterEach(to => {
  router.afterEach(() => {
    fbq('track', 'PageView');
    /** {@link https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications } */

    // 2022-05-03 remove ga
    if (document.location.search == '?td=1') {
      sessionStorage.setItem('isFromThirdParty', 'true');
    }

    // 2022-05-03 remove ga
    /*
    let isFromThirdParty = sessionStorage.getItem('isFromThirdParty');
    if (isFromThirdParty === 'true') {
      gtag('config', process.env.GA_TRACKING_ID, {
        page_referrer: 'www.unipapa.com',
      });
    } else {
      gtag('config', process.env.GA_TRACKING_ID, { page_path: to.fullPath });
    }
    */

    // old version
    /*
    if (!window.pageLoadTimes) {
      window.pageLoadTimes = 0;
    }
    if (document.location.search == '?td=1' && window.pageLoadTimes == 0) {
      gtag('config', process.env.GA_TRACKING_ID, {
        page_referrer: 'www.unipapa.com',
      });
    } else {
      gtag('config', process.env.GA_TRACKING_ID, { page_path: to.fullPath });
    }
    */

    window.pageLoadTimes++;
    // line
    _lt(
      'send',
      'cv',
      {
        type: 'Conversion',
      },
      ['5f49e272-d2ad-4337-891f-bc2c68decee1']
    );
  });
};
