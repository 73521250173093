export default ({ app, redirect, route, query: { referralCode } }) => {
  const isAuthenticated = Boolean(app.$auth.getToken());

  const isRequireAuth = route.matched.some(
    matchedRoute => matchedRoute.meta.isRequireAuth
  );

  const isAnonymousOnly = route.matched.some(
    matchedRoute => matchedRoute.meta.isAnonymousOnly
  );

  if (isRequireAuth && !isAuthenticated) {
    const query = { redirect: route.path };

    if (referralCode) {
      Object.assign(query, { referralCode });
    }

    redirect('/login', query);
  }

  if (isAnonymousOnly && isAuthenticated) {
    // redirect('/');
  }
};
