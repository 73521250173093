const items = [
  { id: 1, name: '客廳' },
  { id: 2, name: '廚房' },
  { id: 3, name: '浴室' },
  { id: 4, name: '書房／辦公室' },
  { id: 5, name: '收納' },
  { id: 6, name: '家電' },
  { id: 7, name: '3C' },
];

export default items;
