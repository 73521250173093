import Vue from 'vue';

export default () => {
  function monthDay(q) {
    try {
      q = q.substr(5, 5).replace(/-/g, '/');
    } catch (e) {
      // yada
    }
    return q;
  }

  Vue.filter('monthDay', monthDay);
};
