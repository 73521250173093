// import { cartMutationHook } from './cart';
// import axios from 'axios';

export const actions = {
  // async nuxtServerInit(store) {
  async nuxtServerInit() {
    /* 
    if (this.app.$auth.getToken()) {
      try {
        let config = {
          headers: {
            authorization: `Bearer ${this.app.$auth.getToken()}`,
          },
        };
        const result = await axios.post(
          `${process.env.API_ORIGIN}/auth/token`,
          {},
          config
        );
        const data = result.data;
        if (data.user) {
          store.commit('user/setUser', {
            user: data.user,
            coupons: data.coupons,
            isNewUser: data.isNewUser,
          });
        }
      } catch (e) {
        // yada
      }
    } */
  },
};
