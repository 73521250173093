const items = [
  {
    typeID: 1,
    id: 1,
    name: 'Pure 衛生紙系列',
  },
  {
    typeID: 1,
    id: 2,
    name: 'Eco Fresh 清潔系列',
  },
  {
    typeID: 2,
    id: 3,
    name: '鱷魚牌 聯名系列',
  },
  {
    typeID: 2,
    id: 4,
    name: 'Unipapa 原創開發',
  },
  {
    typeID: 3,
    id: 5,
    name: 'Unipapa 原創開發',
  },
  // {
  //   typeID: 3,
  //   id: 6,
  //   name: 'Simple cocoon 聯名系列',
  // },
  {
    typeID: 4,
    id: 7,
    name: 'Unipapa 原創開發',
  },
  {
    typeID: 4,
    id: 8,
    name: 'Ozark 聯名系列',
  },
  {
    typeID: 4,
    id: 9,
    name: '刷樂 聯名系列',
  },
  // {
  //   typeID: 4,
  //   id: 10,
  //   name: '嘉雲製傘 聯名系列',
  // },
  {
    typeID: 4,
    id: 11,
    name: 'HOLA 聯名系列',
  },
];

export default items;
