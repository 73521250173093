const items = [
  {
    identifier: 'LIVING',
    collectionName: 'living',
    name: '居家生活',
  },
  {
    identifier: 'CCC',
    collectionName: '3C',
    name: '3C',
  },
  {
    identifier: 'APPLIANCES',
    collectionName: 'appliances',
    name: '家電',
  },
  {
    identifier: 'PERSONAL',
    collectionName: 'personal',
    name: '個人用品',
  },
];

export default items;
