import Vue from 'vue';

export default () => {
  function yyyymmdd(q = null) {
    if (q == null) {
      q = '--';
    } else {
      try {
        let temp = new Date(q).valueOf();
        temp += 60 * 60 * 8 * 1000;
        temp = new Date(temp);
        const year = temp.getFullYear();
        let month = temp.getMonth() + 1;
        let day = temp.getDate();

        month = (month + '').padStart(2, '0');
        day = (day + '').padStart(2, '0');
        q = year + '-' + month + '-' + day;
      } catch (e) {
        // yada
        q = '--';
      }
    }
    return q;
  }

  Vue.filter('yyyymmdd', yyyymmdd);
};
