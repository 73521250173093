const items = [
  { id: 1, name: '每個月送一次', periodTypeID: 1, period: 1 },
  { id: 2, name: '每二個月送一次', periodTypeID: 1, period: 2 },
  { id: 3, name: '每三個月送一次', periodTypeID: 1, period: 3 },
  { id: 4, name: '每四個月送一次', periodTypeID: 1, period: 4 },
  { id: 5, name: '每五個月送一次', periodTypeID: 1, period: 5 },
  { id: 6, name: '每半年送一次', periodTypeID: 1, period: 6 },
];

export default items;
