/**
 * 物流公司
 * @typedef {Object} ShipmentCompany
 * @prop {number} id
 * @prop {string} name
 */

/**
 * 物流公司列表
 * @type {ShipmentCompany[]}
 */
const SHIPMENT_COMPANYS = [
  { id: 1, name: '宅配通' },
  { id: 2, name: '便利帶' },
  { id: 3, name: '嘉里大榮' },
  { id: 4, name: '郵局掛號' },
  { id: 5, name: '新竹物流' },
  { id: 6, name: '全速配(同新竹物流)' },
  { id: 7, name: '黑貓宅急便' },
  { id: 8, name: '錸乾物流整合' },
  { id: 9, name: '超峰快遞' },
  { id: 10, name: '晉越快遞' },
  { id: 11, name: '好運袋(原統群物流)' },
  { id: 12, name: '速遞物流' },
  { id: 13, name: '新航快遞' },
  { id: 14, name: '全球快遞' },
  { id: 15, name: '神形快遞' },
  { id: 16, name: 'DHL' },
  { id: 17, name: '大誠國際通運' },
  { id: 18, name: '通盈通運' },
  { id: 19, name: '自取' },
  { id: 20, name: '超商店到店' },
  { id: 21, name: '順豐速運' },
];

export default SHIPMENT_COMPANYS;
