<template>
  <nuxt v-if="isReady" />
</template>

<script>
export default {
  data() {
    return {
      isReady: false,
    };
  },

  created() {
    this.initCart();
  },

  async mounted() {
    const self = this;
    if (this.$auth.getToken() || !window.isCheckedLoginStatus) {
      try {
        const result = await this.post(`auth/token`, {}).catch(() => {
          // remove cookie token
          self.$auth.logout();
        });
        window.isCheckedLoginStatus = true;

        const data = result.data;

        this.$store.commit('user/setUser', {
          user: data.user,
          coupons: data.coupons,
          isNewUser: data.isNewUser,
        });
      } catch (e) {
        // yada
      }
    }

    this.isReady = true;

    this.initDatalayer();
  },
};
</script>
