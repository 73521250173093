exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".is-push-down_2dIiT{padding-top:var(--header-height)}", ""]);

// exports
exports.locals = {
	"is-push-down": "is-push-down_2dIiT"
};