import Vue from 'vue';

export default () => {
  const formatterCurrency = new Intl.NumberFormat('zh-Hant-TW', {
    style: 'currency',
    currency: 'TWD',
    minimumFractionDigits: 0,
  });

  Vue.filter('currency', formatterCurrency.format);
};
