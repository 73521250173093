export function state() {
  return {
    user: {
      id: null,
      email: '',
      name: '',
      avatar: null,
      receiveName: '',
      receivePhone: '',
      receiveAddress: '',
      receiveCityID: null,
      receiveAreaID: null,
      isNewsletter: null,
      referralCode: null,
      countPlay: null,
      countReferral: null,
      birthday: null,
    },
    coupons: [],
    isLogin: false,
    isNewUser: false,
  };
}

export const mutations = {
  setUser(state, { user = null, coupons = [], isNewUser = false } = {}) {
    state.user = user;
    state.coupons = coupons;
    state.isNewUser = isNewUser;
    if (user != null) {
      state.isLogin = true;
    } else {
      state.isLogin = false;
    }
  },
};
