/**
 * 處理狀態
 */
const PROCESS_STATUS = [
  // { id: 1, identifier: 'PROCESSING', name: '處理中' },
  { id: 1, identifier: 'PROCESSING', name: '訂單處理中' },
  // { id: 2, identifier: 'SHIPPING', name: '已出貨' },
  { id: 2, identifier: 'SHIPPING', name: '完成出貨' },
  { id: 3, identifier: 'RECEIVED', name: '已收貨' },
  { id: 4, identifier: 'RETURN_PROCESSING', name: '退換貨處理中' },
  { id: 5, identifier: 'COMPLETED', name: '已完成' },
  { id: 6, identifier: 'RETURN_COMPLETED', name: '退換貨已完成' },
  { id: 7, identifier: 'ORDER_FROM_FUJIN_SHOP', name: '富錦店訂單' },
];

export default PROCESS_STATUS;
