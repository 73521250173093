import Vue from 'vue'
import Meta from 'vue-meta'
import { createRouter } from './router.js'
import NoSSR from './components/no-ssr.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtLink from './components/nuxt-link.js'
import NuxtError from '..\\src\\layouts\\error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryclient_7f5ea911 from 'nuxt_plugin_sentryclient_7f5ea911' // Source: ./sentry-client.js (ssr: false)
import nuxt_plugin_mixins_5013a1f0 from 'nuxt_plugin_mixins_5013a1f0' // Source: ..\\src\\plugins\\mixins
import nuxt_plugin_assetURL_03d7af69 from 'nuxt_plugin_assetURL_03d7af69' // Source: ..\\src\\plugins\\filters\\assetURL
import nuxt_plugin_currency_3c62671b from 'nuxt_plugin_currency_3c62671b' // Source: ..\\src\\plugins\\filters\\currency
import nuxt_plugin_date_145e7bb8 from 'nuxt_plugin_date_145e7bb8' // Source: ..\\src\\plugins\\filters\\date
import nuxt_plugin_dateSlash_193648a5 from 'nuxt_plugin_dateSlash_193648a5' // Source: ..\\src\\plugins\\filters\\dateSlash
import nuxt_plugin_datetime_f5ff9af6 from 'nuxt_plugin_datetime_f5ff9af6' // Source: ..\\src\\plugins\\filters\\datetime
import nuxt_plugin_monthDay_07125006 from 'nuxt_plugin_monthDay_07125006' // Source: ..\\src\\plugins\\filters\\monthDay
import nuxt_plugin_number_ee54e65a from 'nuxt_plugin_number_ee54e65a' // Source: ..\\src\\plugins\\filters\\number
import nuxt_plugin_pad_e5e9096e from 'nuxt_plugin_pad_e5e9096e' // Source: ..\\src\\plugins\\filters\\pad
import nuxt_plugin_yyyymmdd_09ad5f6a from 'nuxt_plugin_yyyymmdd_09ad5f6a' // Source: ..\\src\\plugins\\filters\\yyyymmdd
import nuxt_plugin_vuetoasted_f6148aee from 'nuxt_plugin_vuetoasted_f6148aee' // Source: ..\\src\\plugins\\vue-toasted (ssr: false)
import nuxt_plugin_vueavatar_48030114 from 'nuxt_plugin_vueavatar_48030114' // Source: ..\\src\\plugins\\vue-avatar
import nuxt_plugin_auth_6a7e4e1e from 'nuxt_plugin_auth_6a7e4e1e' // Source: ..\\src\\plugins\\auth
import nuxt_plugin_tracking_3ed1642d from 'nuxt_plugin_tracking_3ed1642d' // Source: ..\\src\\plugins\\tracking (ssr: false)
import nuxt_plugin_vuejsdialog_675cadfc from 'nuxt_plugin_vuejsdialog_675cadfc' // Source: ..\\src\\plugins\\vuejs-dialog (ssr: false)
import nuxt_plugin_vuesplide_0b33f690 from 'nuxt_plugin_vuesplide_0b33f690' // Source: ..\\src\\plugins\\vue-splide (ssr: false)

// Component: <no-ssr>
Vue.component(NoSSR.name, NoSSR)

// Component: <nuxt-child>
Vue.component(NuxtChild.name, NuxtChild)

// Component: <nuxt-link>
Vue.component(NuxtLink.name, NuxtLink)

// Component: <nuxt>`
Vue.component(Nuxt.name, Nuxt)

// vue-meta configuration
Vue.use(Meta, {
  keyName: 'head', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-n-head', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-n-head-ssr', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'hid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  const registerModule = store.registerModule
  store.registerModule = (path, rawModule, options) => registerModule.call(store, path, rawModule, Object.assign({ preserveState: process.client }, options))

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    router,
    store,
    nuxt: {
      defaultTransition,
      transitions: [ defaultTransition ],
      setTransitions(transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [ transitions ]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },
      err: null,
      dateErr: null,
      error(err) {
        err = err || null
        app.context._errored = !!err
        err = err ? normalizeError(err) : null
        const nuxt = this.nuxt || this.$options.nuxt
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) ssrContext.nuxt.error = err
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    store,
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined
  })

  const inject = function (key, value) {
    if (!key) throw new Error('inject(key, value) has no key provided')
    if (typeof value === 'undefined') throw new Error('inject(key, value) has no value provided')
    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) return
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Vue.prototype.hasOwnProperty(key)) {
        Object.defineProperty(Vue.prototype, key, {
          get() {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Plugin execution

  if (typeof nuxt_plugin_mixins_5013a1f0 === 'function') await nuxt_plugin_mixins_5013a1f0(app.context, inject)
  if (typeof nuxt_plugin_assetURL_03d7af69 === 'function') await nuxt_plugin_assetURL_03d7af69(app.context, inject)
  if (typeof nuxt_plugin_currency_3c62671b === 'function') await nuxt_plugin_currency_3c62671b(app.context, inject)
  if (typeof nuxt_plugin_date_145e7bb8 === 'function') await nuxt_plugin_date_145e7bb8(app.context, inject)
  if (typeof nuxt_plugin_dateSlash_193648a5 === 'function') await nuxt_plugin_dateSlash_193648a5(app.context, inject)
  if (typeof nuxt_plugin_datetime_f5ff9af6 === 'function') await nuxt_plugin_datetime_f5ff9af6(app.context, inject)
  if (typeof nuxt_plugin_monthDay_07125006 === 'function') await nuxt_plugin_monthDay_07125006(app.context, inject)
  if (typeof nuxt_plugin_number_ee54e65a === 'function') await nuxt_plugin_number_ee54e65a(app.context, inject)
  if (typeof nuxt_plugin_pad_e5e9096e === 'function') await nuxt_plugin_pad_e5e9096e(app.context, inject)
  if (typeof nuxt_plugin_yyyymmdd_09ad5f6a === 'function') await nuxt_plugin_yyyymmdd_09ad5f6a(app.context, inject)
  if (typeof nuxt_plugin_vueavatar_48030114 === 'function') await nuxt_plugin_vueavatar_48030114(app.context, inject)
  if (typeof nuxt_plugin_auth_6a7e4e1e === 'function') await nuxt_plugin_auth_6a7e4e1e(app.context, inject)

  if (process.client) {
    if (typeof nuxt_plugin_sentryclient_7f5ea911 === 'function') await nuxt_plugin_sentryclient_7f5ea911(app.context, inject)
    if (typeof nuxt_plugin_vuetoasted_f6148aee === 'function') await nuxt_plugin_vuetoasted_f6148aee(app.context, inject)
    if (typeof nuxt_plugin_tracking_3ed1642d === 'function') await nuxt_plugin_tracking_3ed1642d(app.context, inject)
    if (typeof nuxt_plugin_vuejsdialog_675cadfc === 'function') await nuxt_plugin_vuejsdialog_675cadfc(app.context, inject)
    if (typeof nuxt_plugin_vuesplide_0b33f690 === 'function') await nuxt_plugin_vuesplide_0b33f690(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    app,
    store,
    router
  }
}

export { createApp, NuxtError }
