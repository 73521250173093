const items = [
  { typeID2: 1, id: 1, name: '環保衛生紙', photo: 'living_1.svg' },
  { typeID2: 1, id: 2, name: '超級白衛生紙', photo: 'living_2.svg' },
  { typeID2: 1, id: 3, name: '萬用潔淨布', photo: 'living_3.svg' },

  { typeID2: 2, id: 4, name: '強淨碗盤清潔', photo: 'living_4.svg' },
  { typeID2: 2, id: 5, name: '植淨廚衛清潔', photo: 'living_5.svg' },
  { typeID2: 2, id: 6, name: '家用清潔海棉', photo: 'living_6.svg' },

  { typeID2: 3, id: 7, name: '防蚊卡匣', photo: 'bug_1.svg' },
  { typeID2: 3, id: 8, name: '防蟲卡匣', photo: 'bug_2.svg' },
  { typeID2: 3, id: 9, name: '芳香卡匣', photo: 'bug_3.svg' },
  { typeID2: 3, id: 10, name: '除蚊噴霧', photo: 'bug_4.svg' },
  { typeID2: 3, id: 11, name: '除蟑噴霧', photo: 'bug_5.svg' },
  { typeID2: 3, id: 12, name: '酷黑捕蟑屋', photo: 'bug_6.svg' },

  { typeID2: 4, id: 13, name: '斜打電蚊拍', photo: 'bug_7.svg' },

  { typeID2: 5, id: 14, name: '延長線', photo: 'electrical_1.svg' },
  { typeID2: 5, id: 15, name: '斜打電蚊拍', photo: 'electrical_2.svg' },

  { typeID2: 6, id: 16, name: 'Surco', photo: 'electrical_3.svg' },

  {
    typeID2: 7,
    id: 17,
    name: '可換鞋墊地板拖鞋',
    photo: 'miscellaneous_1.svg',
  },

  { typeID2: 8, id: 18, name: '家用工具組', photo: 'miscellaneous_2.svg' },
  { typeID2: 8, id: 19, name: '精密工具組', photo: 'miscellaneous_3.svg' },

  {
    typeID2: 9,
    id: 20,
    name: 'Soft 中性漱口水',
    photo: 'miscellaneous_4.svg',
  },
  { typeID2: 10, id: 21, name: '嘉雲製傘', photo: 'miscellaneous_5.svg' },
];

export default items;
