import Vue from 'vue';
import VueRouter from 'vue-router';
// import { COLLECTIONS } from '~/constants/_index';

const isProduction = process.env.NODE_ENV === 'production';

Vue.use(VueRouter);

// const collectionNames = COLLECTIONS.map(
//   ({ collectionName }) => collectionName
// ).join('|');

/**
 * Fine tune layout via route metadata
 * @typedef {Object} RouteMetaLayout
 * @property {boolean} [isHeaderFixed=true] - 是否定住Header (default=true)
 * @property {boolean} [isPullUp=false] - 是否把版面主要區塊往上拉，也就是讓Header蓋在主要區塊上 (default=false)
 * @property {boolean} [isFooterSpacing=true] - 是否留距離footer空間
 * @property {string} [theme] - 主題顏色 (default=undefiend)
 */

/**
 * @type { { meta?: RouteMetaLayout }[] }
 */
let routes = [
  {
    name: 'thirdPartyLoginLanding',
    path: '/thirdPartyLoginLanding',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "site" */ '~/pages/thirdPartyLoginLanding.vue').then(
        m => m.default || m
      ),
    meta: {
      isPullUp: false,
    },
  },

  // {
  //   name: 'Debug',
  //   path: '/debug',
  //   caseSensitive: true,
  //   component: () =>
  //     import(/* webpackChunkName: "debug" */ '~/pages/debug.vue').then(
  //       m => m.default || m
  //     ),
  //   meta: {
  //     isPullUp: true,
  //   },
  // },

  {
    name: 'Index',
    path: '/',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Index" */ '~/pages/Index.vue').then(
        m => m.default || m
      ),
    meta: {
      isPullUp: true,
    },
  },

  {
    name: 'Login',
    path: '/login',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Login" */ '~/pages/Login.vue').then(
        m => m.default || m
      ),
    meta: { isAnonymousOnly: true },
  },

  {
    name: 'Search',
    // path: '/search/:searchValue',
    path: '/search',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Search" */ '~/pages/Search.vue').then(
        m => m.default || m
      ),
  },

  {
    name: 'Cart',
    path: '/cart',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Checkout" */ '~/pages/Cart.vue').then(
        m => m.default || m
      ),
  },

  // {
  //   name: 'Cart2',
  //   path: '/cart2',
  //   caseSensitive: true,
  //   component: () =>
  //     import(/* webpackChunkName: "Cart2" */ '~/pages/Cart2.vue').then(
  //       m => m.default || m
  //     ),
  // },

  {
    name: 'Checkout',
    path: '/checkout',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Checkout" */ '~/pages/Checkout.vue').then(
        m => m.default || m
      ),
    meta: { isRequireAuth: true },
  },

  // {
  //   name: 'Testing',
  //   path: '/testing',
  //   caseSensitive: true,
  //   component: () =>
  //     import(/* webpackChunkName: "Testing" */ '~/pages/testing.vue').then(
  //       m => m.default || m
  //     ),
  // },

  {
    // name: 'Testing2',
    path: '/testing2',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Testing" */ '~/pages/testing2.vue').then(
        m => m.default || m
      ),
  },

  /*
  .########..########.....###....##....##.########.
  .##.....##.##.....##...##.##...###...##.##.....##
  .##.....##.##.....##..##...##..####..##.##.....##
  .########..########..##.....##.##.##.##.##.....##
  .##.....##.##...##...#########.##..####.##.....##
  .##.....##.##....##..##.....##.##...###.##.....##
  .########..##.....##.##.....##.##....##.########.
  */
  {
    name: 'BrandListing',
    path: '/brand/listing',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Brands" */ '~/pages/brand/listing.vue').then(
        m => m.default || m
      ),
    meta: {},
  },

  {
    name: 'Brands',
    path: '/brands',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Brands" */ '~/pages/brand/listing.vue').then(
        m => m.default || m
      ),
  },

  {
    name: 'Brand',
    path: '/brands/:id(\\d+)/:name?',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Brands" */ '~/pages/brand/listing.vue').then(
        m => m.default || m
      ),
  },

  /*
  .########..########...#######..########..##.....##..######..########
  .##.....##.##.....##.##.....##.##.....##.##.....##.##....##....##...
  .##.....##.##.....##.##.....##.##.....##.##.....##.##..........##...
  .########..########..##.....##.##.....##.##.....##.##..........##...
  .##........##...##...##.....##.##.....##.##.....##.##..........##...
  .##........##....##..##.....##.##.....##.##.....##.##....##....##...
  .##........##.....##..#######..########...#######...######.....##...
  */
  {
    name: 'ProductListing',
    path: '/product/listing/:typeID',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "ProductListing" */ '~/pages/product/listing.vue').then(
        m => m.default || m
      ),
    meta: {},
  },

  {
    name: 'Product',
    path: '/products/:id(\\d+)/:name?',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "ProductItem" */ '~/pages/product/item.vue').then(
        m => m.default || m
      ),
    meta: {},
  },

  /*
  .......##..#######..########.
  .......##.##.....##.##.....##
  .......##.##.....##.##.....##
  .......##.##.....##.########.
  .##....##.##.....##.##.....##
  .##....##.##.....##.##.....##
  ..######...#######..########.
  */
  {
    name: 'Jobs',
    path: '/jobs',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Job" */ '~/pages/job/listing.vue').then(
        m => m.default || m
      ),
  },

  {
    name: 'Job',
    path: '/jobs/:id(\\d+)',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Job" */ '~/pages/job/item.vue').then(
        m => m.default || m
      ),
  },

  /*
  .##.....##..######..########.########.
  .##.....##.##....##.##.......##.....##
  .##.....##.##.......##.......##.....##
  .##.....##..######..######...########.
  .##.....##.......##.##.......##...##..
  .##.....##.##....##.##.......##....##.
  ..#######...######..########.##.....##
  */
  {
    name: 'User',
    path: '/user',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "User" */ '~/pages/User.vue').then(
        m => m.default || m
      ),
    meta: { isRequireAuth: true },
  },

  /*
  ..#######..########..########..########.########.
  .##.....##.##.....##.##.....##.##.......##.....##
  .##.....##.##.....##.##.....##.##.......##.....##
  .##.....##.########..##.....##.######...########.
  .##.....##.##...##...##.....##.##.......##...##..
  .##.....##.##....##..##.....##.##.......##....##.
  ..#######..##.....##.########..########.##.....##
  */
  {
    name: 'Orders',
    path: '/orders',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Orders" */ '~/pages/Orders.vue').then(
        m => m.default || m
      ),
    meta: { isRequireAuth: true },
  },

  {
    path: '/orders/:id(\\d+)',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Checkout" */ '~/pages/Order.vue').then(
        m => m.default || m
      ),
    children: [
      {
        name: 'Order_Payment',
        path: 'payment',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "Checkout" */ '~/pages/Order_Payment.vue').then(
            m => m.default || m
          ),
      },
      {
        name: 'Order_Default',
        path: '',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "Checkout" */ '~/pages/Order_Default.vue').then(
            m => m.default || m
          ),
      },
    ],
    // meta: { isRequireAuth: true },
  },

  {
    path: '/orders2/:id(\\d+)',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Order" */ '~/pages/Order.vue').then(
        m => m.default || m
      ),
    children: [
      {
        name: 'Order_Payment2',
        path: 'payment',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "Order_Payment" */ '~/pages/Order_Payment2.vue').then(
            m => m.default || m
          ),
      },
      {
        name: 'Order_Default2',
        path: '',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "Order_Default" */ '~/pages/Order_Default.vue').then(
            m => m.default || m
          ),
      },
    ],
    meta: { isRequireAuth: true },
  },

  {
    path: '/orders/:id(\\d+)/complete',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "Checkout" */ '~/pages/OrderComplete.vue').then(
        m => m.default || m
      ),
    meta: { isRequireAuth: true },
  },

  {
    path: '/orders/:id(\\d+)/messages',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "OrderMessages" */ '~/pages/OrderMessages.vue').then(
        m => m.default || m
      ),
    meta: { isRequireAuth: true },
  },

  {
    path: '/orders/:id(\\d+)/return',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "OrderReturn" */ '~/pages/OrderReturn.vue').then(
        m => m.default || m
      ),
    meta: { isRequireAuth: true },
  },

  /*
  ..######..########.....###...
  .##....##.##.....##...##.##..
  .##.......##.....##..##...##.
  .##.......########..##.....##
  .##.......##........#########
  .##....##.##........##.....##
  ..######..##........##.....##
  */
  // {
  //   name: 'CPA',
  //   path: '/cpa',
  //   caseSensitive: true,
  //   component: () =>
  //     import(/* webpackChunkName: "CPA" */ '~/pages/CPA.vue').then(
  //       m => m.default || m
  //     ),
  // },

  /*
  ..######..##.....##.########...######...######..########..####.########..########
  .##....##.##.....##.##.....##.##....##.##....##.##.....##..##..##.....##.##......
  .##.......##.....##.##.....##.##.......##.......##.....##..##..##.....##.##......
  ..######..##.....##.########...######..##.......########...##..########..######..
  .......##.##.....##.##.....##.......##.##.......##...##....##..##.....##.##......
  .##....##.##.....##.##.....##.##....##.##....##.##....##...##..##.....##.##......
  ..######...#######..########...######...######..##.....##.####.########..########
  */
  {
    name: 'OrderSubscribeIndex',
    path: '/order-subscribe/index',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "OrderSubscribeIndex" */ '~/pages/orderSubscribe/index.vue').then(
        m => m.default || m
      ),
    meta: {
      isFooterSpacing: false,
      isPullUp: true,
    },
  },

  {
    name: 'OrderSubscribeCart',
    path: '/order-subscribe/cart',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "OrderSubscribeCart" */ '~/pages/orderSubscribe/cart.vue').then(
        m => m.default || m
      ),
    meta: {
      isFooterSpacing: false,
      isRequireAuth: true,
    },
  },

  /*
  .##.....##..######..########.########......######..##.....##.########...######...######..########..####.########..########
  .##.....##.##....##.##.......##.....##....##....##.##.....##.##.....##.##....##.##....##.##.....##..##..##.....##.##......
  .##.....##.##.......##.......##.....##....##.......##.....##.##.....##.##.......##.......##.....##..##..##.....##.##......
  .##.....##..######..######...########......######..##.....##.########...######..##.......########...##..########..######..
  .##.....##.......##.##.......##...##............##.##.....##.##.....##.......##.##.......##...##....##..##.....##.##......
  .##.....##.##....##.##.......##....##.....##....##.##.....##.##.....##.##....##.##....##.##....##...##..##.....##.##......
  ..#######...######..########.##.....##.....######...#######..########...######...######..##.....##.####.########..########
  */

  {
    name: 'user-order-subscribe/listing',
    path: '/user-order-subscribe/listing',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "UserOrderSubscribeListing" */ '~/pages/userOrderSubscribe/listing.vue').then(
        m => m.default || m
      ),
    meta: { isRequireAuth: true },
  },

  {
    name: 'user-order-subscribe/item',
    path: '/user-order-subscribe/item/:md5',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "UserOrderSubscribeItem" */ '~/pages/userOrderSubscribe/item.vue').then(
        m => m.default || m
      ),
    meta: { isRequireAuth: true },
  },

  {
    name: 'user-order-subscribe/log',
    path: '/user-order-subscribe/log/:md5',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "UserOrderSubscribeLog" */ '~/pages/userOrderSubscribe/log.vue').then(
        m => m.default || m
      ),
    meta: { isRequireAuth: true },
  },

  {
    name: 'user-order-subscribe/payment',
    path: '/user-order-subscribe/payment/:md5',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "UserOrderSubscribePayment" */ '~/pages/userOrderSubscribe/payment.vue').then(
        m => m.default || m
      ),
    meta: { isRequireAuth: true },
  },

  // {
  //   name: 'user-order-subscribe/message',
  //   path: '/user-order-subscribe/message/:md5',
  //   caseSensitive: true,
  //   component: () =>
  //     import(/* webpackChunkName: "UserOrderSubscribeMessage" */ '~/pages/userOrderSubscribe/message.vue').then(
  //       m => m.default || m
  //     ),
  //   meta: { isRequireAuth: true },
  // },

  {
    name: 'user-order-subscribe/order',
    path: '/user-order-subscribe/order/:md5',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "UserOrderSubscribeOrder" */ '~/pages/userOrderSubscribe/order.vue').then(
        m => m.default || m
      ),
    meta: { isRequireAuth: true },
  },

  /*
  ..######..##.....##..#######..########.
  .##....##.##.....##.##.....##.##.....##
  .##.......##.....##.##.....##.##.....##
  ..######..#########.##.....##.########.
  .......##.##.....##.##.....##.##.......
  .##....##.##.....##.##.....##.##.......
  ..######..##.....##..#######..##.......
  */
  {
    name: 'ShopIndex',
    path: '/shop/index',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "ShopIndex" */ '~/pages/shop/index.vue').then(
        m => m.default || m
      ),
  },

  /*
  ....###....########..########.####..######..##.......########
  ...##.##...##.....##....##.....##..##....##.##.......##......
  ..##...##..##.....##....##.....##..##.......##.......##......
  .##.....##.########.....##.....##..##.......##.......######..
  .#########.##...##......##.....##..##.......##.......##......
  .##.....##.##....##.....##.....##..##....##.##.......##......
  .##.....##.##.....##....##....####..######..########.########
  */
  {
    name: 'ArticleListing',
    path: '/article/listing',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "ArticleListing" */ '~/pages/article/listing.vue').then(
        m => m.default || m
      ),
  },
  {
    name: 'ArticleItem',
    // path: '/article/item/:id',
    path: '/article/item/:url',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "ArticleItem" */ '~/pages/article/item.vue').then(
        m => m.default || m
      ),
  },

  /*
  ....###....########...#######..##.....##.########
  ...##.##...##.....##.##.....##.##.....##....##...
  ..##...##..##.....##.##.....##.##.....##....##...
  .##.....##.########..##.....##.##.....##....##...
  .#########.##.....##.##.....##.##.....##....##...
  .##.....##.##.....##.##.....##.##.....##....##...
  .##.....##.########...#######...#######.....##...
  */
  {
    name: 'AboutService',
    path: '/about/service',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "About" */ '~/pages/about/service.vue').then(
        m => m.default || m
      ),
  },
  {
    name: 'AboutSupport',
    path: '/about/support',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "About" */ '~/pages/about/support.vue').then(
        m => m.default || m
      ),
  },

  {
    name: 'AboutRefund',
    path: '/about/refund',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "About" */ '~/pages/about/refund.vue').then(
        m => m.default || m
      ),
  },

  {
    name: 'AboutPrivacy',
    path: '/about/privacy',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "About" */ '~/pages/about/privacy.vue').then(
        m => m.default || m
      ),
  },

  {
    name: 'AboutFaq',
    path: '/about/faq',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "About" */ '~/pages/about/faq.vue').then(
        m => m.default || m
      ),
  },

  // {
  //   name: 'AboutEnterprise',
  //   path: '/about/enterprise',
  //   caseSensitive: true,
  //   component: () =>
  //     import(/* webpackChunkName: "About" */ '~/pages/about/enterprise.vue').then(
  //       m => m.default || m
  //     ),
  //   meta: {
  //     theme: 'dark',
  //     isFooterSpacing: false,
  //   },
  // },

  {
    name: 'EnterprisePurchaseCreate',
    path: '/enterprisePurchase/create',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "EnterprisePurchaseCreate" */ '~/pages/enterprisePurchase/create.vue').then(
        m => m.default || m
      ),
    meta: {
      // theme: 'dark',
      // isFooterSpacing: false,
    },
  },

  /*
  .########.....###.....######...########
  .##.....##...##.##...##....##..##......
  .##.....##..##...##..##........##......
  .########..##.....##.##...####.######..
  .##........#########.##....##..##......
  .##........##.....##.##....##..##......
  .##........##.....##..######...########
  */
  {
    name: 'PageItem',
    path: '/page/item/:url',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "PageItem" */ '~/pages/page/item.vue').then(
        m => m.default || m
      ),
    meta: {},
  },

  /*
  .########..########...#######..##.....##..#######..########.####..#######..##....##
  .##.....##.##.....##.##.....##.###...###.##.....##....##.....##..##.....##.###...##
  .##.....##.##.....##.##.....##.####.####.##.....##....##.....##..##.....##.####..##
  .########..########..##.....##.##.###.##.##.....##....##.....##..##.....##.##.##.##
  .##........##...##...##.....##.##.....##.##.....##....##.....##..##.....##.##..####
  .##........##....##..##.....##.##.....##.##.....##....##.....##..##.....##.##...###
  .##........##.....##..#######..##.....##..#######.....##....####..#######..##....##
  */

  // {
  //   name: 'promotion20221111',
  //   path: '/promotion/2022-1111',
  //   caseSensitive: true,
  //   component: () =>
  //     import(/* webpackChunkName: "2022-1111" */ '~/pages/promotion/2022-1111.vue').then(
  //       m => m.default || m
  //     ),
  //   meta: {
  //     isFooterSpacing: false,
  //   },
  // },

  // {
  //   name: 'promotion20231111',
  //   path: '/promotion/2023-1111',
  //   caseSensitive: true,
  //   component: () =>
  //     import(/* webpackChunkName: "2023-1111" */ '~/pages/promotion/2023-1111.vue').then(
  //       m => m.default || m
  //     ),
  //   meta: {
  //     isFooterSpacing: false,
  //   },
  // },

  {
    name: 'promotion20241111',
    path: '/promotion/2024-1111',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "2024-1111" */ '~/pages/promotion/2024-1111.vue').then(
        m => m.default || m
      ),
    meta: {
      isFooterSpacing: false,
    },
  },

  // {
  //   name: 'promotion2023outdoor',
  //   path: '/promotion/outdoor',
  //   caseSensitive: true,
  //   component: () =>
  //     import(/* webpackChunkName: "2023-outdoor" */ '~/pages/promotion/2023-outdoor.vue').then(
  //       m => m.default || m
  //     ),
  //   meta: {
  //     isFooterSpacing: false,
  //   },
  // },

  // {
  //   name: 'promotion2020anniversary',
  //   path: '/promotion/2020-anniversary',
  //   caseSensitive: true,
  //   component: () =>
  //     import(/* webpackChunkName: "2020-anniversary" */ '~/pages/promotion/2020-anniversary.vue').then(
  //       m => m.default || m
  //     ),
  //   meta: {
  //     isPullUp: true,
  //     isFooterSpacing: false,
  //   },
  // },

  // {
  //   name: 'promotion20200401',
  //   path: '/promotion/2020-0401',
  //   caseSensitive: true,
  //   component: () =>
  //     import(/* webpackChunkName: "2020-0401" */ '~/pages/promotion/2020-0401.vue').then(
  //       m => m.default || m
  //     ),
  //   meta: {
  //     isPullUp: true,
  //     isFooterSpacing: false,
  //   },
  // },

  /*
  ..######.....###....##.....##.########.....###....####..######...##....##
  .##....##...##.##...###...###.##.....##...##.##....##..##....##..###...##
  .##........##...##..####.####.##.....##..##...##...##..##........####..##
  .##.......##.....##.##.###.##.########..##.....##..##..##...####.##.##.##
  .##.......#########.##.....##.##........#########..##..##....##..##..####
  .##....##.##.....##.##.....##.##........##.....##..##..##....##..##...###
  ..######..##.....##.##.....##.##........##.....##.####..######...##....##
  */
  {
    name: 'CampaignItem',
    path: '/campaign/item/:url',
    caseSensitive: true,
    component: () =>
      // import(/* webpackChunkName: "CampaignItem" */ '~/pages/campaign/item.vue').then(
      import(/* webpackChunkName: "CampaignItem" */ '~/pages/campaign/item.2023-1111.vue').then(
        m => m.default || m
      ),
    meta: {
      isHideLink: true,
    },
  },

  {
    // name: 'CampaignItem',
    path: '/warranty/register',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "CampaignItem" */ '~/pages/warranty/register.vue').then(
        m => m.default || m
      ),
    meta: {
      // isHideLink: true,
    },
  },

  {
    name: 'GroupbuyItem',
    path: '/groupbuy/:url',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "GroupBuyItem" */ '~/pages/groupbuy/item.vue').then(
        m => m.default || m
      ),
    meta: {
      isHideLink: true,
    },
  },
  {
    name: 'loop',
    path: '/products/loop',
    caseSensitive: true,
    component: () =>
      import(/* webpackChunkName: "loop" */ '~/pages/products/loop.vue').then(
        m => m.default || m
      ),
    meta: {
      // isHeaderFixed: false,
      isPullUp: true,
      isFooterSpacing: false,
    },
  },
];

/*
  ..######..####.##....##..######...##.......########....########..########...#######..########..##.....##..######..########
  .##....##..##..###...##.##....##..##.......##..........##.....##.##.....##.##.....##.##.....##.##.....##.##....##....##...
  .##........##..####..##.##........##.......##..........##.....##.##.....##.##.....##.##.....##.##.....##.##..........##...
  ..######...##..##.##.##.##...####.##.......######......########..########..##.....##.##.....##.##.....##.##..........##...
  .......##..##..##..####.##....##..##.......##..........##........##...##...##.....##.##.....##.##.....##.##..........##...
  .##....##..##..##...###.##....##..##.......##..........##........##....##..##.....##.##.....##.##.....##.##....##....##...
  ..######..####.##....##..######...########.########....##........##.....##..#######..########...#######...######.....##...
  */
// include products when developing
routes = [
  ...routes,
  ...[
    {
      name: 'extension-cord65W',
      path: '/products/extension-cord65W',
      caseSensitive: true,
      component: () =>
        import(/* webpackChunkName: "extension-cord65W" */ '~/pages/products/extension-cord65W.vue').then(
          m => m.default || m
        ),
      meta: {
        isPullUp: false,
        isFooterSpacing: false,
      },
    },

    {
      path: '/products/extension-cord',
      caseSensitive: true,
      component: () =>
        import(/* webpackChunkName: "extension-cord" */ '~/pages/products/extension-cord.vue').then(
          m => m.default || m
        ),
      children: [
        {
          name: 'extension-cord-white',
          path: '',
          caseSensitive: true,
          // component: () =>
          //   import(/* webpackChunkName: "extension-cord" */ '~/pages/products/extension-cord-white.vue').then(
          //     m => m.default || m
          //   ),
          meta: {
            isPullUp: true,
            isFooterSpacing: false,
          },
        },
        {
          name: 'extension-cord-black',
          path: 'black',
          caseSensitive: true,
          // component: () =>
          //   import(/* webpackChunkName: "extension-cord" */ '~/pages/products/extension-cord-black.vue').then(
          //     m => m.default || m
          //   ),
          meta: {
            isPullUp: true,
            isFooterSpacing: false,
            theme: 'dark',
          },
        },
      ],
      meta: {
        isPullUp: true,
        isFooterSpacing: false,
      },
    },

    {
      name: 'extension-cord-modular-origin',
      path: '/products/extension-cord-modular-origin',
      caseSensitive: true,
      component: () =>
        import('~/pages/products/extension-cord-modular-origin.vue').then(
          m => m.default || m
        ),
      meta: {
        isPullUp: false,
        isFooterSpacing: false,
      },
    },

    {
      name: 'extension-cord-modular',
      path: '/products/extension-cord-modular',
      caseSensitive: true,
      component: () =>
        import('~/pages/products/extension-cord-modular.vue').then(
          m => m.default || m
        ),
      meta: {
        isPullUp: false,
        isFooterSpacing: false,
      },
    },

    {
      name: 'extension-cord-modular2',
      path: '/products/extension-cord-modular2',
      caseSensitive: true,
      redirect: '/products/extension-cord-modular',
    },

    {
      name: 'extension-cord-modular-test',
      path: '/products/extension-cord-modular-test',
      caseSensitive: true,
      component: () =>
        import('~/pages/products/extension-cord-modular-test.vue').then(
          m => m.default || m
        ),
      meta: {
        isPullUp: false,
        isFooterSpacing: false,
      },
    },

    {
      name: 'testing',
      path: '/products/testing',
      caseSensitive: true,
      component: () =>
        import('~/pages/products/testing.vue').then(m => m.default || m),
      meta: {
        isPullUp: false,
        isFooterSpacing: false,
      },
    },
    {
      name: 'testing2',
      path: '/products/testing2',
      caseSensitive: true,
      component: () =>
        import('~/pages/products/testing2.vue').then(m => m.default || m),
      meta: {
        isPullUp: false,
        isFooterSpacing: false,
      },
    },

    {
      name: 'extension-cord-modular-backbrew',
      path: '/products/extension-cord-modular-backbrew',
      caseSensitive: true,
      component: () =>
        import('~/pages/products/extension-cord-modular-backbrew.vue').then(
          m => m.default || m
        ),
      meta: {
        isPullUp: false,
        isFooterSpacing: false,
      },
    },
    {
      path: '/products/67w-charger',
      caseSensitive: true,
      component: () =>
        import('~/pages/products/67w-charger.vue').then(m => m.default || m),
      meta: {
        isPullUp: false,
        isFooterSpacing: false,
      },
    },

    // {
    //   name: 'ggxup-extension-cord',
    //   path: '/products/ggxup-extension-cord',
    //   caseSensitive: true,
    //   component: () =>
    //     import(/* webpackChunkName: "guerrilla" */ '~/pages/products/ggxup-extension-cord.vue').then(
    //       m => m.default || m
    //     ),
    //   meta: {
    //     // isHeaderFixed: false,
    //     isPullUp: true,
    //     isFooterSpacing: false,
    //   },
    // },
  ],
];

// not for dev, for speedup
if (isProduction) {
  routes = [
    ...routes,
    ...[
      {
        name: 'productLightTheNight',
        path: '/products/light-the-night',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "light-the-night" */ '~/pages/products/light-the-night.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: false,
          isFooterSpacing: false,
        },
      },

      {
        name: 'spray',
        path: '/products/spray',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "spray" */ '~/pages/products/spray.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: true,
          isFooterSpacing: false,
        },
      },
      {
        name: 'grease-washing',
        path: '/products/grease-washing',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "grease-washing" */ '~/pages/products/grease-washing.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: true,
        },
      },

      {
        name: 'mosquito-away',
        path: '/products/mosquito-away',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "mosquito-away" */ '~/pages/products/mosquito-away.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: true,
          isFooterSpacing: false,
        },
      },

      {
        name: 'dishwashing',
        path: '/products/dishwashing',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "dishwashing" */ '~/pages/products/dishwashing.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: true,
        },
      },

      {
        name: 'theta',
        path: '/products/theta',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "theta" */ '~/pages/products/theta.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: false,
        },
      },

      {
        name: 'mouth-wash',
        path: '/products/mouth-wash',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "mouth-wash" */ '~/pages/products/mouth-wash.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: true,
          isFooterSpacing: false,
        },
      },

      {
        name: 'unipapa-pure',
        path: '/products/unipapa-pure',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "unipapa-pure" */ '~/pages/products/unipapa-pure.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: false,
          isPullUp: true,
        },
      },

      {
        name: 'flip-mirror',
        path: '/products/flip-mirror',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "flip-mirror" */ '~/pages/products/flip-mirror.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: false,
          isFooterSpacing: false,
        },
      },
      {
        name: 'uphanger',
        path: '/products/uphanger',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "uphanger" */ '~/pages/products/uphanger.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: false,
          isFooterSpacing: false,
        },
      },

      {
        name: 'crocoil-roach-trap',
        path: '/products/crocoil-roach-trap',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "crocoil-roach-trap" */ '~/pages/products/crocoil-roach-trap.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: true,
          theme: 'dark',
          isFooterSpacing: false,
        },
      },

      {
        name: 'sponge',
        path: '/products/sponge',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "sponge" */ '~/pages/products/sponge.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: true,
          isFooterSpacing: false,
        },
      },

      // {
      //   name: 'frangrance',
      //   path: '/products/frangrance',
      //   caseSensitive: true,
      //   component: () =>
      //     import(/* webpackChunkName: "frangrance" */ '~/pages/products/frangrance.vue').then(
      //       m => m.default || m
      //     ),
      //   meta: {
      //     isPullUp: true,
      //     isFooterSpacing: false,
      //   },
      // },

      {
        name: 'surco',
        path: '/products/surco',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "surco" */ '~/pages/products/surco.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: true,
          isFooterSpacing: false,
        },
      },

      {
        name: 'room-slippers',
        path: '/products/room-slippers',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "uni-swap" */ '~/pages/products/room-slippers.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: true,
          isFooterSpacing: false,
        },
      },

      {
        name: 'bath-sponge',
        path: '/products/bath-sponge',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "bath-sponge" */ '~/pages/products/bath-sponge.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: true,
          isFooterSpacing: false,
        },
      },

      {
        name: 'line-buckle',
        path: '/products/line-buckle',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "line-buckle" */ '~/pages/products/line-buckle.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: true,
          isFooterSpacing: false,
        },
      },

      // {
      //   name: 'lips-cup',
      //   path: '/products/lips-cup',
      //   caseSensitive: true,
      //   component: () =>
      //     import(/* webpackChunkName: "lips-cup" */ '~/pages/products/lips-cup.vue').then(
      //       m => m.default || m
      //     ),
      //   meta: {
      //     isPullUp: true,
      //     isFooterSpacing: false,
      //     theme: 'dark',
      //   },
      // },

      {
        name: 'pocket-paper',
        path: '/products/pocket-paper',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "pocket-paper" */ '~/pages/products/pocket-paper.vue').then(
            m => m.default || m
          ),
        meta: {
          isPullUp: true,
          isFooterSpacing: false,
        },
      },

      {
        name: 'tissue-holder',
        path: '/products/tissue-holder',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "tissue-holder" */ '~/pages/products/tissue-holder.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: true,
        },
      },

      // 碗盤 dishwashing
      // 水垢 scale-remover
      // 重油 grease-washing
      // 除黴 mildew-cleaner

      {
        name: 'scale-remover',
        path: '/products/scale-remover',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "scale-remover" */ '~/pages/products/scale-remover.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: true,
        },
      },
      {
        name: 'mildew-cleaner',
        path: '/products/mildew-cleaner',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "mildew-cleaner" */ '~/pages/products/mildew-cleaner.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: true,
        },
      },

      // {
      //   name: 'repellent',
      //   path: '/products/repellent',
      //   caseSensitive: true,
      //   component: () =>
      //     import(/* webpackChunkName: "repellent" */ '~/pages/products/repellent.vue').then(
      //       m => m.default || m
      //     ),
      //   meta: {
      //     isFooterSpacing: true,
      //
      //   },
      // },

      // 掛鉤 wall-hook
      // C字扣 cable-hook
      // 整線扣 cable-organizer
      {
        name: 'wall-hook',
        path: '/products/wall-hook',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "wall-hook" */ '~/pages/products/wall-hook.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: true,
        },
      },

      {
        name: 'cable-hook',
        path: '/products/cable-hook',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "cable-hook" */ '~/pages/products/cable-hook.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: true,
        },
      },

      {
        name: 'cable-organizer',
        path: '/products/cable-organizer',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "cable-organizer" */ '~/pages/products/cable-organizer.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: true,
        },
      },

      {
        name: 'bed-linen',
        path: '/products/bed-linen',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "bed-linen" */ '~/pages/products/bed-linen.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: true,
        },
      },

      {
        name: 'bed-linen2',
        path: '/products/bed-linen2',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "bed-linen" */ '~/pages/products/bed-linen.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: true,
        },
      },

      {
        name: 'summer-beverage-cassette',
        path: '/products/summer-beverage-cassette',
        caseSensitive: true,
        component: () =>
          import(/* webpackChunkName: "summer-beverage-cassette" */ '~/pages/products/summer-beverage-cassette.vue').then(
            m => m.default || m
          ),
        meta: {
          isFooterSpacing: true,
        },
      },
    ],
  ];
}

if (process.client) {
  window.history.scrollRestoration = 'manual';
}

const scrollBehavior = function(to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false;

  // if no children detected
  if (to.matched.length < 2) {
    // scroll to the top of the page
    position = { x: 0, y: 0 };
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 };
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition;
  }

  /* eslint-disable compat/compat */
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(position);
    }, 100);

    /*
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash;
        // CSS.escape() is not supported with IE and Edge.
        if (
          typeof window.CSS !== 'undefined' &&
          typeof window.CSS.escape !== 'undefined'
        ) {
          hash = '#' + window.CSS.escape(hash.substr(1));
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash };
          }
        } catch (e) {
          console.warn(
            'Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).'
          );
        }
      }
    });
    */
  });
  /* eslint-enable compat/compat */
};

export function createRouter() {
  return new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior,
  });
}
