/*
type	int	卡片種類
1 = VISA
2 = MasterCard
3 = JCB
4 = Union Pay
5 = AMEX
*/

const items = [
  { id: 1, name: 'VISA', img: 'card-visa.png' },
  { id: 2, name: 'MasterCard', img: 'card-mastercard.png' },
  { id: 3, name: 'JCB', img: 'card-jcb.png' },
  { id: 4, name: 'Union Pay', img: 'card-union-pay.png' },
  { id: 5, name: 'AMEX', img: 'card-american-express.png' },
];

export default items;
