import { PRODUCT_REDIRECT_MAP } from '../constants/_index';

export default ({ params, redirect }) => {
  for (const [name, IDs] of PRODUCT_REDIRECT_MAP) {
    for (const id of IDs) {
      if (params.id == id) {
        return redirect('/products/' + name);
      }
    }
  }
};
