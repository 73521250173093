export function state() {
  return {
    isShowLoginModal: false,
    isShowProfileModal: false,
    isShowSearch: false,
    // notification: [{}, {}],
    // 1 = change credit
    // 2 = change plan
    // 3 = cancel
    // 4 = delivery
    // notification: [
    //   { typeID: 1 },
    //   { typeID: 2, code: 'aaaa1234', id: 123 },
    //   { typeID: 3, code: 'aaaa1234' },
    //   { typeID: 4, code: 'aaaa1234' },
    // ],

    notification: [],

    isShow1111: false,
    isInvalidToken: false,
  };
}

export const mutations = {
  changeNotification(state, index) {
    state.notification[index].isShowConfirm = true;
  },

  addNotification(state, q) {
    if (q.typeID == 2) {
      q.isShowConfirm = false;
    }
    // state.notification.push(q);
    state.notification.unshift(q);
  },

  // setShow1111(state, is) {
  //   state.isShow1111 = is;
  // },

  showLoginModal(state, is) {
    state.isShowLoginModal = is;
  },

  showProfileModal(state, is) {
    state.isShowProfileModal = is;
  },

  showSearchModal(state, is) {
    state.isShowSearch = is;
  },

  clearNotifications(state) {
    state.notification = [];
  },

  clearNotification(state, index) {
    state.notification.splice(index, 1);
  },

  setInvalid(state, is) {
    state.isInvalidToken = is;
  },
};
