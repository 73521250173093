import Vue from 'vue';
import Toasted from 'vue-toasted';

export default ({ app: { router } }) => {
  Vue.use(Toasted, {
    duration: 3000,
    router,
  });

  Vue.toasted.register('success', ({ message }) => message || '', {
    icon: 'check',
  });

  Vue.toasted.register('warning', ({ message }) => message || '', {
    icon: 'warning',
  });

  Vue.toasted.register('mail', ({ message }) => message || '請收信', {
    icon: 'mail_outline',
  });

  Vue.toasted.register(
    'error',
    ({ message }) => message || '發生錯誤，請重新嘗試。',
    {
      icon: 'error_outline',
    }
  );
};
