exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (min-width:768px){.bg_2FpuZ{background-image:linear-gradient(90deg,#fff 50%,#f9f9f9 0)}}", ""]);

// exports
exports.locals = {
	"bg": "bg_2FpuZ"
};